import { Info } from "@mui/icons-material";
import { Alert, CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import { client } from "../../utils/apiClient";
import { Root, classes } from "./styles";
import FooterListing from "../../SharedComponents/ListingFooter";
import { useGlobalContext } from "../../context/Global";

export default function ListingPage() {
    // When visiting listing page, clear selected language
    const { setLanguage } = useGlobalContext();
    setLanguage("");

    const [loading, setLoading] = React.useState<boolean>(true);
    const [restaurants, setRestaurants] = React.useState<Array<any>>([]);
    const [searchRestaurant, setSearchRestaurant] = React.useState("");
    const [filteredRestaurants, setFilteredRestaurants] = React.useState<
        Array<any>
    >([]);

    React.useEffect(() => {
        client("restaurant").then((response) => {
            if (Array.isArray(response)) {
                setRestaurants(response);
                setLoading(false);
            }
        });
    }, []);

    React.useEffect(() => {
        const filtered = restaurants
            .filter((rest) =>
                rest.display_name
                    ?.toLowerCase()
                    ?.includes(searchRestaurant.toLowerCase())
            )
            .sort((a, b) => a.display_name.localeCompare(b.display_name)); // Sort alphabetically

        if (searchRestaurant.length === 0) {
            setFilteredRestaurants([]); // No resturant should be shown default
        } 
        else{
            setFilteredRestaurants(filtered.slice(0, 6)); // Limit to 6 results when user search
        }
    }, [searchRestaurant, restaurants]);

    return (
        <div>
            <Root container className={classes.container}>
                <Grid item xs={12} className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src="/stellar-menus-for-white-bg.png"
                        alt="Stellar Menus Logo"
                    ></img>
                </Grid>
                <Grid item xs={12}>
                    <h1 className={classes.title}>Find your menu...</h1>
                    <form className={classes.searchForm}>
                        <TextField
                            fullWidth
                            className={classes.searchTxt}
                            label="Enter restaurant name"
                            variant="outlined"
                            style={{ marginBottom: "30px" }}
                            value={searchRestaurant}
                            onChange={(e) =>
                                setSearchRestaurant(e.target.value)
                            }
                        ></TextField>
                    </form>
                    {loading ? (
                        <div className={classes.spinnerContainer}>
                            <CircularProgress color="primary"></CircularProgress>
                        </div>
                    ) : (
                        <>
                            {(!filteredRestaurants.length && searchRestaurant.length > 0) && (
                                <div className={classes.alertContainer}>
                                    <Alert
                                        icon={<Info></Info>}
                                        className={classes.alert}
                                        variant="filled"
                                        severity="warning"
                                    >
                                        Oops! Restaurant not found...
                                    </Alert>
                                </div>
                            )}
                            {filteredRestaurants.map((restaurant: any, i) => (
                                <a
                                    key={restaurant._id + i}
                                    className={classes.restaurantItem}
                                    href={`/${restaurant.identifier}`}
                                >
                                    {restaurant.display_name}
                                </a>
                            ))}
                        </>
                    )}
                    <FooterListing></FooterListing>
                </Grid>
            </Root>
        </div>
    );
}
